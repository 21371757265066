/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../../schemas/initial_values";
import { Grid, Icon, IconButton } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import calibrationService from "shared/services/calibration/calibration.service";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import toastError from "shared/components/snackbar/error/toastError";
import validations from "../../schemas/validations";
import FormFieldFileButton from "shared/components/form_field_file_button";
import CircularProgressWithLabel from "shared/components/loader/circularProgressWithLabel";

import styles from "./styles.module.css";

const XMLForm = ({ xmlCalibrated }) => {
  const [XMLName, setXMLName] = useState("");
  const [isUploadingDoc, setIsUploadingDoc] = useState(false);
  const [state, setState] = useState(0);
  const { setXMLCalibrated } = xmlCalibrated;
  const [isMounted, setIsMounted] = useState(true);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setXMLName(file.name);
      handleFile(file, "file");
    }
  };

  const handleSubmit = async (values, actions) => {
    handleFile(values.xmlLink, "link");
  };

  const refreshLinkXML = async () => {
    handleFile(XMLName, "link");
  };

  const handleFile = async (file, type) => {
    try {
      const formD = new FormData();
      if (type === "file") formD.append("xml_file", file);
      if (type === "link") formD.append("xml_link", file);
      setIsUploadingDoc(true);
      const res = await calibrationService.createXML(formD, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        setState(progress - 1);
      });
      if (res && isMounted) {
        setIsUploadingDoc(false);
        setXMLCalibrated(true);
        setXMLName(res.file_link);
      }
      toastSuccess("XML registrado com sucesso");
    } catch (e) {
      toastError(e.message);
    } finally {
      setState(0);
      setIsUploadingDoc(false);
    }
  };

  const loadXML = async () => {
    try {
      const data = await calibrationService.getXML();
      if (data) {
        setXMLName(data.file_name);
        setXMLCalibrated(true);
      }
    } catch (e) {
      setXMLCalibrated(false);
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadXML();
    return () => {
      setXMLName("");
      setIsMounted(false);
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations[0]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, touched, isSubmitting, setFieldValue, handleBlur }) => {
        const { xmlLink: xmlLinkV } = values;
        return (
          <Form id="xml-form" autoComplete="off">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={4}>
                <FormField
                  name="link"
                  label="Link do xml *"
                  type="text"
                  value={xmlLinkV}
                  onChange={(event) => {
                    const { value } = event.target;
                    setFieldValue("xmlLink", value);
                    if (value) setFieldValue("xmlFile", "");
                  }}
                />
              </Grid>
              <Grid item>
                <MDButton color="primary" type="submit">
                  Carregar XML
                </MDButton>
              </Grid>
              <Grid item className={styles.gridFlex}>
                {XMLName && (
                  <MDButton
                    disabled={isUploadingDoc}
                    color="primary"
                    onClick={refreshLinkXML}
                  >
                    ATUALIZAR XML
                  </MDButton>
                )}
                {/* <FormFieldFileButton
                  name="xmlFile"
                  accept="text/xml"
                  label="Escolher arquivo XML"
                  onChange={(event) => {
                    const { files } = event.target;
                    if (files[0]) {
                      setFieldValue("xmlFile", files[0]);
                      setFieldValue("xmlLink", "");
                      handleFileChange(event);
                    }
                  }}
                /> */}
                {isUploadingDoc && (
                  <MDBox ml={2} mt={1}>
                    <CircularProgressWithLabel value={state} size={33} />
                  </MDBox>
                )}
              </Grid>
            </Grid>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography variant="h5" mr={2}>
                Nome do arquivo:{" "}
              </MDTypography>
              <MDTypography className={styles.xmlName} variant="body2">
                {XMLName || "<XML não selecionado>"}
              </MDTypography>
              {XMLName && (
                <IconButton
                  size="medium"
                  title="Deletar XML"
                  onClick={() => {
                    setFieldValue("xmlFile", "");
                    setFieldValue("xmlLink", "");
                    setXMLName("");
                  }}
                >
                  <Icon>highlight_off</Icon>
                </IconButton>
              )}
            </MDBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default XMLForm;
