/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";

// canvas
import CanvasBox from "shared/pages/banners/components/CanvasBox";
import {
  addText,
  addImg,
} from "shared/pages/banners/components/canvasFunctions/index";
import { htmlToImageZoomOut } from "shared/services/html2Canvas";

import StorageTypes from "shared/services/localStorage/storageTypes";
import localStorageService from "shared/services/localStorage";
import removeBackgroundService from "shared/services/removeBackground/removeBackground.service";
import dragNDropService from "shared/services/dragNDrop";
import { base64StringToBlob } from "utils";
import { useNavigate } from "react-router-dom";

function CanvasContainer({
  canvasData,
  formData,
  canvasCreated,
  removeBackground,
}) {
  const navigate = useNavigate();
  const { values } = formData;
  const { bannerName: bannerNameV } = values;
  // canvas
  const { canvas, setCanvas } = canvasData;
  const {
    isRemoveBackground,
    setIsRemoveBackground,
    removeBackgroundLoading,
    setRemoveBackgroundLoading,
  } = removeBackground;
  const { bannerCreated } = canvasCreated;

  const handleRemoveBackground = async (imgSrc, clientX, clientY, type) => {
    setRemoveBackgroundLoading(true);
    const formD = new FormData();
    if (type === "base64") {
      const blob = base64StringToBlob(imgSrc);
      formD.append("image_file", blob);
    } else {
      formD.append("image_link", imgSrc);
    }

    const res = await removeBackgroundService.create(formD);
    addImg(res, canvas);
    setRemoveBackgroundLoading(false);
    setIsRemoveBackground(false);
  };

  const dropElement = async (e, type) => {
    const dropData = dragNDropService.drop(e);

    const clientX =
      e.clientX - canvas.upperCanvasEl.getBoundingClientRect().left;
    const clientY =
      e.clientY - canvas.upperCanvasEl.getBoundingClientRect().top;

    if (dropData.type === "IMG") {
      if (isRemoveBackground) {
        handleRemoveBackground(
          dropData.element.src,
          clientX - 200,
          clientY - 200,
          "base64"
        );
      } else {
        addImg(dropData.element.src, canvas, clientX - 100, clientY - 100);
      }
    } else {
      addText(
        canvas,
        dropData.element.textContent,
        "Arial",
        14,
        "#000000",
        clientX - 30,
        clientY - 30
      );
    }
  };

  return (
    <Grid item xs={12} sm={9}>
      <MDBox display="flex" flexDirection="column">
        <MDBox>
          <CanvasBox
            functionState={{ canvas, setCanvas }}
            onDrop={dropElement}
          />
        </MDBox>

        <MDBox display="flex" gap={2}>
          <MDButton type="submit" variant="gradient" color="info">
            salvar
          </MDButton>
          <MDButton
            disabled={bannerCreated === false}
            variant="gradient"
            color="info"
            onClick={() => {
              const storage = localStorageService.get(
                StorageTypes.BANNER_MODEL
              );
              const { width, height } = storage;
              canvas.discardActiveObject().renderAll();

              htmlToImageZoomOut(canvas, bannerNameV);
            }}
          >
            Download
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              canvas.remove(...canvas.getObjects());
              canvas.discardActiveObject();
              canvas.requestRenderAll();
            }}
          >
            Limpar
          </MDButton>
          <MDButton
            style={{ marginLeft: "auto" }}
            variant="gradient"
            color="error"
            onClick={() => {
              navigate("/banners", { replace: true });
            }}
          >
            cancelar
          </MDButton>
        </MDBox>
      </MDBox>
    </Grid>
  );
}
export default CanvasContainer;
