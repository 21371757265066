/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Card,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import PopUp, { dialogButton } from "shared/components/pop_up";
import { useNavigate } from "react-router-dom";
import MDProgress from "shared/components/MDProgress";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import { errorMultipleMsgToast } from "utils/errorStatusCode";

import templatesService from "shared/services/templates/templates.service";
import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";

import styles from "./styles.module.css";
import { userLogged } from "utils/essentialData";

// function srcset(image, size, rows = 1, cols = 1) {
//   return {
//     src: `${image}?w=${size * cols}&h=${size * rows}&fit=cover&auto=format`,
//     srcSet: `${image}?w=${size * cols}&h=${
//       size * rows
//     }&fit=cover&auto=format&dpr=2 2x`,
//   };
// }

function imageListPaginatorCSS(theme) {
  return {
    backgroundColor: theme.palette.primary.focus,
    color: theme.palette.common.white,
    marginTop: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.focus,
    },
  };
}

function Templates() {
  const theme = useTheme();
  const { breakpoints } = theme;
  const getColumns = (width) => {
    if (width < breakpoints.values.sm) {
      return 2;
    } else if (width < breakpoints.values.md) {
      return 4;
    } else if (width < breakpoints.values.lg) {
      return 5;
    } else if (width < breakpoints.values.xl) {
      return 6;
    } else {
      return 7;
    }
  };
  const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth));
  };

  const navigate = useNavigate();

  const [openPopUp, setOpenPopUp] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  // const [rowSelected, setRowSelected] = useState();
  // const [filter, setFilter] = useState("");
  // const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  // const [tableTotal, setTableTotal] = useState(0);
  const [tableLastPage, setTableLastPage] = useState(false);

  const [modelData, setModelData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setModelData(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadTemplates = async (page = 1, limit = 10, filter = "") => {
    try {
      setLoadingData(true);
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await templatesService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );
      if (res && !tableLastPage) {
        const mergedArray = dataTable.concat(res.data);
        setDataTable(mergedArray);
        // setTableTotal(res.meta.totalCount);
        setTableLastPage(res.meta.isLastPage);
        setTablePage(res.meta.currentPage);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //     loadTemplates(1, tableLimit);
  //     setTablePage(1);
  //     setFilterSearch("");
  //   }
  //   setFilter(value);
  // };

  // const handleClickSearch = () => {
  //   setFilterSearch(filter.trim());
  //   setTablePage(1);
  //   loadTemplates(1, tableLimit, filter);
  // };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      const id = modelData.id;
      await templatesService.delete(id);
      toastSuccess("Modelo deletado com sucesso");
      const res = dataTable.filter((v) => v.id !== id);
      setDataTable(res);
      handleClose();
    } catch (e) {
      errorMultipleMsgToast(e);
    } finally {
      setOpenPopUp(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    loadTemplates(tablePage, tableLimit);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          <MDBox p={2}>
            <MDBox lineHeight={1} mb={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Modelos
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                    <MDButton
                    disabled={userLogged().banner_credits === 0}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() => navigate("/templates/criar")}
                    >
                      novo modelo
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              {loadingData ? (
                <MDProgress
                  variant="gradient"
                  variantProgress="indeterminate"
                  color="primary"
                />
              ) : (
                <MDBox className={styles.imageListContainer}>
                  <ImageList
                    // gap={12}
                    cols={columns}
                    variant="quilted"
                    rowHeight={100}
                  >
                    {dataTable.map((item, index) => {
                      const column =
                        Number(item.width) <= Number(item.height) ? 1 : 2;
                      const row =
                        Number(item.height) <= Number(item.width) ? 1 : 2;

                      return (
                        <ImageListItem
                          key={index}
                          rows={row}
                          cols={column}
                          className={styles.itemList}
                        >
                          {item.background_image_link ? (
                            <img
                              src={item.background_image_link}
                              srcSet={`${item.background_image_link}?w=248&fit=crop&auto=format&dpr=2 2x`}
                              alt={item.name}
                              loading="lazy"
                            />
                          ) : (
                            <MDBox
                              className={styles.imageTemplateColor}
                              sx={{
                                background: item.background_color,
                              }}
                            />
                          )}
                          <ImageListItemBar
                            title={
                              <Tooltip
                                className={styles.itemListItem}
                                title={item.name}
                                arrow
                              >
                                <span>{item.name}</span>
                              </Tooltip>
                            }
                            subtitle={`${item.width} x ${item.height}`}
                            actionIcon={
                              <IconButton
                                className={styles.imageDescriptionIcon}
                                aria-label={`info about ${item.title}`}
                                onClick={(e) => handleClick(e, item)}
                              >
                                <Icon>pending</Icon>
                              </IconButton>
                            }
                          />
                          <Menu
                            id="images-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                localStorageService.set(
                                  StorageTypes.BANNER_MODEL,
                                  { ...modelData, type: "list" }
                                );
                                navigate("/banners/criar");
                                handleClose();
                              }}
                            >
                              Criar Banner
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenPopUp(true);
                              }}
                            >
                              Deletar Modelo
                            </MenuItem>
                          </Menu>
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                </MDBox>
              )}
            </MDBox>
            {!tableLastPage && (
              <MDBox display="flex" justifyContent="center">
                <IconButton
                  onClick={(e) => {
                    loadTemplates(tablePage + 1, tableLimit);
                  }}
                  style={imageListPaginatorCSS(theme)}
                >
                  <Icon>keyboard_double_arrow_down</Icon>
                </IconButton>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </DashboardLayout>
      <PopUp
        open={openPopUp}
        onClose={() => {}}
        title="Deletar modelo"
        contentText={`Deseja deletar permanentemente o modelo?`}
        actions={[
          <MDButton
            color="dark"
            onClick={handleCancelDelete}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="error"
            onClick={handleApplyDelete}
            style={dialogButton}
            key="confirm"
          >
            confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default Templates;
