// local
// const base_url = "http://127.0.0.1:3333";

// dev
// const base_url = "https://api.dev.bannerfy.com.br";

// homol
// const base_url = "https://api.hml.bannerfy.com.br";

// prod
const base_url = "https://api.bannerfy.com.br";

export default base_url;
