import { isAxiosError } from "axios";
import {
  XML_UPLOAD_API,
  XML_READER_API,
  XML_CALIBRATION_API,
} from "./calibration";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import ReactDOMServer from "react-dom/server";

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class CalibrationService {
  createXML = async (body, loadFunction = () => {}) => {
    try {
      const response = await customAxios.post(XML_UPLOAD_API, body, {
        onUploadProgress: (event) => loadFunction(event),
      });

      if (!response) new Error("Não foi possível registrar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar XML";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMsgHttp(error.response?.data?.message, defaultMsg)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  getXML = async () => {
    try {
      const response = await customAxios.get(`${XML_UPLOAD_API}`);

      if (!response) new Error("Não foi possível carregar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = "Não foi possível criar XML";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("XML não registrado");

          case 417:
            throw new Error(
              errorMsgHttp(error.response?.data?.message, defaultMsg)
            );

          default:
            throw new Error("Não foi possível carregar XML");
        }
      }

      throw new Error(error);
    }
  };

  readerXML = async (pid) => {
    try {
      const response = await customAxios.get(`${XML_READER_API}/${pid}`);

      if (!response) new Error("Não foi possível carregar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("XML não registrado");

          default:
            throw new Error("Não foi possível carregar XML");
        }
      }

      throw new Error(error);
    }
  };

  deleteXML = async (uuid) => {
    try {
      const response = await customAxios.delete(`${XML_UPLOAD_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar XML";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  createCalibration = async (body) => {
    try {
      const response = await customAxios.post(XML_CALIBRATION_API, body);

      if (!response) new Error("Não foi possível criar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar Calibragem";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  getCalibration = async () => {
    try {
      const response = await customAxios.get(`${XML_CALIBRATION_API}`);

      if (!response) new Error("Não foi possível carregar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("Calibragem não registrada");

          default:
            throw new Error("Não foi possível carregar Calibragem");
        }
      }

      throw new Error(error);
    }
  };

  deleteCalibration = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${XML_CALIBRATION_API}/${uuid}`
      );

      if (!response) new Error("Não foi possível apagar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar Calibragem";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new CalibrationService();
