import * as Yup from "yup";

const validFileExtensions = { image: ["ttf", "otf"] };

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const fontAccept = [
  "abf",
  "afm",
  "bco",
  "bdf",
  "bez",
  "cfn",
  "chr",
  "dfont",
  "chr",
  "dfont",
  "dwf",
  "e24",
  "eot",
  "fli",
  "fon",
  "fot",
  "fnt",
  "gf",
  "inf",
  "L30",
  "mf",
  "mgf",
  "mmm",
  "odt",
  "otf",
  "otl",
  "p9",
  "p24",
  "pcf",
  "pfa",
  "pfb",
  "pfm",
  "pk",
  "pl",
  "ps",
  "psf",
  "pso",
  "pxl",
  "qfm",
  "sfl",
  "sfp",
  "sfs",
  "snf",
  "spd",
  "suit",
  "tdf",
  "tfm",
  "ttc",
  "ttf",
  "vf",
  "vpl",
  "woff",
  "woff2",
];

const validations = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    font: Yup.mixed()
      .required("Por favor, selecione um arquivo")
      .test("file", "Formato de arquivo inválido.", (value) => {
        if (!value) return false;
        const font_split = value?.name?.split(".");
        const font_mime = font_split[font_split?.length - 1];
        const type = value.type ? value.type : font_mime;
        return value && fontAccept.includes(type);
      }),
  }),
];

export default validations;
